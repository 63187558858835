body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 15px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross {
  display: none;
}

